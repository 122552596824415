body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.preloader{
  background: #d6d6d687;
  color: #363636;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.QRtv{
  position: fixed;
  right: 0;
  bottom: 0;
  max-width: 20%;
  text-align: right;
  z-index: 99;
}

.popupframe{
  background: #d6d6d687;
  color: #363636;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
}
.popupframe .contents {
  max-width: 80%;
  width: 100%;
  max-height: 90%;
  top: 3%;
  position: fixed;
  background: #ffffff;
}
.popupframe .closebtn{
  position: absolute;
  z-index: 121;
  right: 0;
  top:0;
  background-color: #363636;
  color: #ffffff;

}

.modal-popup{
  background: #d6d6d687;
  color: #363636;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.modal-popup .contents {
  max-width: 80%;
  width: 100%;
  max-height: 90%;
  margin: auto;
  background: #ffffff;
}

.modal-popup .closebtn{
  position: fixed;
  z-index: 121;
  right: 15px;
  top:15px;
  background-color: #363636;
  color: #ffffff;

}
.mbox{
  position: relative;
}

.mbox .btn-delete{
  display: none;
  position: absolute;
  top:2px;
  right:2px;
}
.mbox:hover .btn-delete, .mbox:focus .btn-delete{
  display: block;
}
.bg-dark-theme{
  color: #ffffff;
  background-color: #1f191a;
}

.w100{
  width: 100%;
}

.max100{
  max-width: 100%;
}
.thumb{
  max-width: 200px;
}

.devicelist { list-style: none; padding: 0; margin: 0; display: flex; justify-content: center; align-items: center; align-self: center; flex-wrap: wrap; }

.devicelist li{ margin: 10px; padding: 15px; border: 1px solid #363636; text-align: center; border-radius: 15px; }


@media print{
  .no-print{
    display: none;
  }
}

@media screen and (max-width: 963px) {
  .embed-responsive.embed-responsive-21by9 {
      height: 75vh;
  }

  .modal-popup .contents {
    max-width: 80%;
    width: 100%;
    max-height: 90%;
    top: 3%;
    position: fixed;
    background: #ffffff;
  }
  
}